import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import Props from 'components/Props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=Accordion,AccordionItem",
        "componentName": "Accordion,AccordionItem"
      }}>{`<Accordion id="myAccordion">
  <AccordionItem title="Lorem ipsum dolor sit amet">
    <Paragraph>
      Nunc congue vehicula lacus, condimentum tincidunt sapien aliquet id. Ut
      convallis sollicitudin sodales. Nullam pellentesque magna et mattis
      vehicula. Etiam aliquam magna feugiat, lobortis justo eu, commodo erat.
      Duis a tempus sem, eu aliquet enim.
    </Paragraph>
  </AccordionItem>
  <AccordionItem title="Neque porro quisquam est qui">
    <Paragraph>
      Maecenas risus tellus, pulvinar ullamcorper aliquam ut, luctus quis nisl.
      Donec laoreet mi ac nunc fringilla facilisis. Aliquam sollicitudin ligula
      ut ligula dignissim, sed placerat magna scelerisque. Sed ac nulla vel sem
      condimentum maximus eu quis tellus.
    </Paragraph>
  </AccordionItem>
  <AccordionItem title="Maecenas pellentesque quam nec aliquam tempus">
    <Paragraph>
      Morbi varius sed libero pulvinar faucibus. Fusce molestie nisi id tellus
      dignissim, quis elementum urna varius. Phasellus blandit volutpat diam,
      non fringilla purus ornare id.
    </Paragraph>
  </AccordionItem>
</Accordion>
`}</code></pre>
    <h2 {...{
      "id": "props",
      "style": {
        "position": "relative"
      }
    }}>{`Props`}</h2>
    <Props identifier="Accordion" mdxType="Props" />
    <h2 {...{
      "id": "children",
      "style": {
        "position": "relative"
      }
    }}>{`Children`}</h2>
    <p>{`All direct children of an `}<inlineCode parentName="p">{`Accordion`}</inlineCode>{` must be `}<inlineCode parentName="p">{`AccordionItem`}</inlineCode>{` components.
However, the children of the `}<inlineCode parentName="p">{`AccordionItem`}</inlineCode>{` components can be any component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Accordion id="myAccordion">
  <AccordionItem title="Lorem ipsum dolor sit amet">
    <Flex justifyContent="space-between" alignItems="flex-end">
      <TextField label="My form" />
      <Button>Submit</Button>
    </Flex>
  </AccordionItem>
  <AccordionItem title="Neque porro quisquam est qui">
    <BrandedHeading>Breaking title</BrandedHeading>
    <Paragraph>
      Morbi varius sed libero pulvinar faucibus. Fusce molestie nisi id tellus
      dignissim, quis elementum urna varius. Phasellus blandit volutpat diam,
      non fringilla purus ornare id.
    </Paragraph>
  </AccordionItem>
  <AccordionItem title="Maecenas pellentesque quam nec aliquam tempus">
    <img
      src="http://placekitten.com/500/200"
      alt="cat"
      style={{
        maxWidth: '100%',
        height: 'auto',
        width: 'auto',
      }}
    />
  </AccordionItem>
</Accordion>
`}</code></pre>
    <h2 {...{
      "id": "initialexpandedindex",
      "style": {
        "position": "relative"
      }
    }}>{`InitialExpandedIndex`}</h2>
    <p><inlineCode parentName="p">{`default: -1`}</inlineCode></p>
    <p>{`To have one item open by default on initial render, pass in the
`}<inlineCode parentName="p">{`initialExpandedIndex`}</inlineCode>{` index.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Accordion id="myAccordion" initialExpandedIndex={1}>
  <AccordionItem title="Lorem ipsum dolor sit amet">
    <Paragraph>
      Nunc congue vehicula lacus, condimentum tincidunt sapien aliquet id. Ut
      convallis sollicitudin sodales. Nullam pellentesque magna et mattis
      vehicula. Etiam aliquam magna feugiat, lobortis justo eu, commodo erat.
      Duis a tempus sem, eu aliquet enim.
    </Paragraph>
  </AccordionItem>
  <AccordionItem title="Neque porro quisquam est qui">
    <Paragraph>
      Maecenas risus tellus, pulvinar ullamcorper aliquam ut, luctus quis nisl.
      Donec laoreet mi ac nunc fringilla facilisis. Aliquam sollicitudin ligula
      ut ligula dignissim, sed placerat magna scelerisque. Sed ac nulla vel sem
      condimentum maximus eu quis tellus.
    </Paragraph>
  </AccordionItem>
  <AccordionItem title="Maecenas pellentesque quam nec aliquam tempus">
    <Paragraph>
      Morbi varius sed libero pulvinar faucibus. Fusce molestie nisi id tellus
      dignissim, quis elementum urna varius. Phasellus blandit volutpat diam,
      non fringilla purus ornare id.
    </Paragraph>
  </AccordionItem>
</Accordion>
`}</code></pre>
    <h2 {...{
      "id": "collapsible",
      "style": {
        "position": "relative"
      }
    }}>{`Collapsible`}</h2>
    <p><inlineCode parentName="p">{`default: false`}</inlineCode></p>
    <p>{`By default, once an item has been opened, it can only be closed by opening
another. If you want to allow users to close all items, set `}<inlineCode parentName="p">{`collapsible`}</inlineCode>{` to
true.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Accordion id="myAccordion" collapsible={true}>
  <AccordionItem title="Lorem ipsum dolor sit amet">
    <Paragraph>
      Nunc congue vehicula lacus, condimentum tincidunt sapien aliquet id. Ut
      convallis sollicitudin sodales. Nullam pellentesque magna et mattis
      vehicula. Etiam aliquam magna feugiat, lobortis justo eu, commodo erat.
      Duis a tempus sem, eu aliquet enim.
    </Paragraph>
  </AccordionItem>
  <AccordionItem title="Neque porro quisquam est qui">
    <Paragraph>
      Maecenas risus tellus, pulvinar ullamcorper aliquam ut, luctus quis nisl.
      Donec laoreet mi ac nunc fringilla facilisis. Aliquam sollicitudin ligula
      ut ligula dignissim, sed placerat magna scelerisque. Sed ac nulla vel sem
      condimentum maximus eu quis tellus.
    </Paragraph>
  </AccordionItem>
  <AccordionItem title="Maecenas pellentesque quam nec aliquam tempus">
    <Paragraph>
      Morbi varius sed libero pulvinar faucibus. Fusce molestie nisi id tellus
      dignissim, quis elementum urna varius. Phasellus blandit volutpat diam,
      non fringilla purus ornare id.
    </Paragraph>
  </AccordionItem>
</Accordion>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      